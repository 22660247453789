@media mq-mobile
  #mobile-nav
    position: absolute
    top: 0
    left: 0
    width: mobile-nav-width
    height: 100%
    background: color-mobile-nav-background
    border-right: 1px solid #fff

@media mq-mobile
  .mobile-nav-link
    display: block
    color: color-grey
    text-decoration: none
    padding: 15px 20px
    font-weight: bold
    &:hover
      color: #fff
