#footer
  background: color-footer-background
  padding: 50px 0
  border-top: 1px solid color-border
  color: color-grey
  a
    color: color-link
    text-decoration: none
    &:hover
      text-decoration: underline

#footer-info
  line-height: line-height
  font-size: 0.85em