$block-caption
  text-decoration: none
  text-transform: uppercase
  letter-spacing: 2px
  color: color-grey
  margin-bottom: 1em
  margin-left: 5px
  line-height: 1em
  text-shadow: 0 1px #fff
  font-weight: bold

$block
  background: #fff
  box-shadow: 1px 2px 3px #ddd
  border: 1px solid color-border
  border-radius: 0px

$base-style
  h1
    font-size: 2em
  h2
    font-size: 1.5em
  h3
    font-size: 1.3em
  h4
    font-size: 1.2em
  h5
    font-size: 1em
  h6
    font-size: 1em
    color: color-grey
  hr
    border: 1px dashed color-border
  strong
    font-weight: bold
  em, cite
    font-style: italic
  sup, sub
    font-size: 0.75em
    line-height: 0
    position: relative
    vertical-align: baseline
  sup
    top: -0.5em
  sub
    bottom: -0.2em
  small
    font-size: 0.85em
  acronym, abbr
    border-bottom: 1px dotted
  ul, ol, dl
    margin: 0 20px
    line-height: line-height
  ul, ol
    ul, ol
      margin-top: 0
      margin-bottom: 0
  ul
    list-style: disc
  ol
    list-style: decimal
  dt
    font-weight: bold